import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseDownloadService, BaseService } from './base.service';
import { Estimate } from '@lui/shared/models/estimate.model';
import { lastValueFrom, Observable } from 'rxjs';
import { MapImage } from '@lui/shared/components/map/map-image.model';
import { PrintoutDialogOptions } from '@lui/shared/components/printout-dialog/printout-dialog-options.model';
import { PrintoutDialogComponent } from '@lui/shared/components/printout-dialog/printout-dialog.component';
//import { PrintoutType } from '@lui/shared/models/classifier.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LoadingService } from './loading.service';
import { FileMetadata } from '@lui/shared/models/file.model';
import { NzImageService } from 'ng-zorro-antd/image';
import { PrintoutType } from '@lui/shared/models';

@Injectable({ providedIn: 'root' })
export class EstimateService extends BaseDownloadService<Estimate> {
    constructor(httpClient: HttpClient,
        private modalService: NzModalService,
        private msgService: NzMessageService,
        private loadingService: LoadingService,
        protected nzImageService: NzImageService) {
        super(httpClient, '/api/v1/estimates/', nzImageService);
    }

    async downloadAttachment(estimateId: number, attachment: string, isMapImage: boolean = false): Promise<void> {
        return this.handleFileDownloading(lastValueFrom(this.httpClient.get<FileMetadata>(`${this.baseApiUrl}${estimateId}/${isMapImage}/${attachment}`)));
    }

    async deleteAttachment(estimateId: number, attachment: string, isMapImage: boolean = false): Promise<void> {
        return lastValueFrom(this.httpClient.delete<void>(`${this.baseApiUrl}${estimateId}/${isMapImage}/${attachment}`));
    }

    addAttachment(estimateId: number, formData: FormData): Promise<boolean> {
        return lastValueFrom(this.httpClient.post<boolean>(`${this.baseApiUrl}${estimateId}/attachments`, formData));
    }

    addMapImage(estimateId: number, mapImage: MapImage): Promise<boolean> {
        return lastValueFrom(this.httpClient.post<boolean>(`${this.baseApiUrl}${estimateId}/map-images`, mapImage));
    }

    toggleIncludeInPrintout(estimateId: number, attachmentId: number, includeInPrintout: boolean): Promise<boolean> {
        return lastValueFrom(this.httpClient.post<boolean>(`${this.baseApiUrl}${estimateId}/attachments/${attachmentId}/${includeInPrintout}`, null));
    }

    async downloadPrintout(estimateId: number, printoutOptions: PrintoutDialogOptions): Promise<string> {
        return this.handleFileDownloading(lastValueFrom(this.httpClient.get<FileMetadata>(`${this.baseApiUrl}${estimateId}/printout/${printoutOptions.userId}/${printoutOptions.printoutTemplateId}`)));
    }

    async print(estimate: Estimate): Promise<void> {
        const modal = this.modalService.create({
            nzTitle: 'Izdrukas iestatījumi',
            nzContent: PrintoutDialogComponent
        });

        modal.afterOpen.subscribe(() => {
            const component = modal.getContentComponent();
            component.printoutType = PrintoutType.Estimate;
            component.orderTypeId = estimate.ordersTypeId;
            component.loadTemplates();
        });

        modal.afterClose.subscribe(async (printoutOptions: PrintoutDialogOptions) => {
            if (printoutOptions) {
                try {
                    this.loadingService.isLoading.next(true);
                    await this.downloadPrintout(estimate.id, printoutOptions);
                } catch (e) {
                    console.log(e);
                    this.msgService.error('Neizdevās lejupielādēt izdruku!');
                } finally {
                    this.loadingService.isLoading.next(false);
                }
            }
        });
    }
}
