import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { IconsProviderModule } from 'src/app/icons-provider.module';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';

import { HasPermissionDirective, HasAnyPermissionDirective, ValidationErrorDirective } from './directives';
import {
    UpsertAddressComponent,
    UpsertFieldComponent,
    SearchFieldComponent,
    DepartmentSelectComponent,
    UpsertAccountComponent,
    UpsertContactComponent,
    UpsertClientComponent,
    ValidationErrorComponent,
    TrueFalseTableIconComponent
} from './components';
import { MapComponent } from './components/map/map.component';
import { DraggableUnitDirective } from './directives/draggable-unit.directive';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PrintoutDialogComponent } from './components/printout-dialog/printout-dialog.component';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFlexModule } from 'ng-zorro-antd/flex';

const MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
];

const NG_ZORRO_MODULE_LIST = [
    NzFormModule,
    NzButtonModule,
    NzIconModule,
    NzSelectModule,
    NzInputModule,
    NzRadioModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzInputNumberModule,
    NzCheckboxModule,
    IconsProviderModule,
    NzSpinModule,
    NzLayoutModule,
    NzTableModule,
    NzAlertModule,
    NzPageHeaderModule,
    NzMenuModule,
    NzModalModule,
    NzTypographyModule,
    NzToolTipModule,
    NzMessageModule,
    NzCollapseModule,
    NzUploadModule,
    NzTagModule,
    NzPopoverModule,
    NzDividerModule,
    NzImageModule,
    NzAvatarModule,
    NzDropDownModule,
    NzFlexModule
];

const LUIDAIS_COMPONENTS = [
    UpsertAccountComponent,
    UpsertAddressComponent,
    UpsertFieldComponent,
    SearchFieldComponent,
    DepartmentSelectComponent,
    UpsertContactComponent,
    UpsertClientComponent,
    ValidationErrorComponent,
    MapComponent,
    PrintoutDialogComponent,
    TrueFalseTableIconComponent
];

const LUIDAIS_DIRECTIVES = [
    HasPermissionDirective,
    HasAnyPermissionDirective,
    ValidationErrorDirective,
    DraggableUnitDirective
];

@NgModule({
    imports: [...MODULES,
    ...NG_ZORRO_MODULE_LIST
    ],

    declarations: [
        ...LUIDAIS_DIRECTIVES,
        ...LUIDAIS_COMPONENTS
    ],

    exports: [...MODULES,
    ...NG_ZORRO_MODULE_LIST,

    ...LUIDAIS_DIRECTIVES,
    ...LUIDAIS_COMPONENTS
    ]
})
export class SharedModule { }
