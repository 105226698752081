import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { NzImageService } from 'ng-zorro-antd/image';
import { CommandResult } from '@lui/shared/models/command-result.model';
import { BaseModel } from '@lui/shared/models';
import { catchError, Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { FileMetadata } from '@lui/shared/models/file.model';
import { BaseTableResult } from '@lui/shared/models/base-table-result.model';

export abstract class BaseService<T extends BaseModel> {
    constructor(protected httpClient: HttpClient, protected baseApiUrl: string) {}

    getAllServerSidePaginated(
        pageIndex: number,
        pageSize: number,
        sortField: string | null,
        sortOrder: string | null,
        filters: Array<{ key: string; value: string }>,
    ): Observable<CommandResult<BaseTableResult<T>>> {
        let httpParams = new HttpParams()
            .append('page', `${pageIndex}`)
            .append('pageSize', `${pageSize}`);

        if (sortField) {
            httpParams = httpParams.append('orderField', `${sortField}`);
        }
        if (sortOrder) {
            httpParams = httpParams.append('orderDir', `${sortOrder}`);
        }

        if (filters) {
            filters.forEach((filter) => {
                httpParams = httpParams.append(filter.key, filter.value);
            });
        }
        return this.httpClient.get<CommandResult<BaseTableResult<T>>>(`${this.baseApiUrl}` + 'serverside', {
            params: httpParams,
        });
    }

    getAll(): Observable<CommandResult<T[]>> {
        return this.httpClient.get<CommandResult<T[]>>(this.baseApiUrl);
    }

    search(text: string): Observable<CommandResult<T[]>> {
        return this.httpClient.get<CommandResult<T[]>>(`${this.baseApiUrl}search/${text}`);
    }

    getSingle(id: number): Promise<CommandResult<T>> {
        return this.httpClient.get<CommandResult<T>>(this.baseApiUrl + id).toPromise();
    }

    create(obj: T | FormData): Promise<number> {
        return this.httpClient.post<number>(this.baseApiUrl, obj).toPromise();
    }

    update(id: number, obj: T): Promise<void> {
        obj.id = id;
        return this.httpClient.put<void>(this.baseApiUrl, obj).toPromise();
    }

    delete(id: number): Promise<void> {
        return this.httpClient.delete<void>(this.baseApiUrl + id).toPromise();
    }
}

export abstract class BaseDownloadService<T extends BaseModel> extends BaseService<T> {
    constructor(
        protected httpClient: HttpClient,
        protected baseApiUrl: string,
        protected nzImageService: NzImageService,
    ) {
        super(httpClient, baseApiUrl);
    }

    protected handleFileDownloading(requestPromise: Promise<FileMetadata>): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            requestPromise.then(
                (fileMetadata) => {
                    try {
                        const link = `${environment.apiBaseUrl}/api/v1/authorized-files/${fileMetadata.id}`;

                        if (fileMetadata.mimeType === 'image/jpeg' || fileMetadata.mimeType === 'image/png') {
                            const previewRef = this.nzImageService.preview([
                                {
                                    src: link,
                                },
                            ]);
                            previewRef.previewInstance.operations.push({
                                icon: 'download',
                                type: 'download',
                                onClick: () => {
                                    window.open(`${link}/download`);
                                },
                            });
                        } else {
                            window.open(link, '_blank');
                        }

                        resolve();
                    } catch {
                        console.error('Couldnt save file');
                    }
                },
                (err: HttpErrorResponse) => {
                    const reader: FileReader = new FileReader();
                    reader.readAsText(err.error);
                    reader.onload = (e: Event) => {
                        try {
                            const errmsg = JSON.parse((<any>e.target).result);
                            reject(
                                new HttpErrorResponse({
                                    error: errmsg,
                                    headers: err.headers,
                                    status: err.status,
                                    statusText: err.statusText,
                                    url: err.url,
                                }),
                            );
                        } catch (e) {
                            reject(err);
                        }
                    };

                    reader.onerror = (e) => {
                        reject(err);
                    };
                },
            );
        });
    }
}
